import React from "react";
import Dropdown from "react-bootstrap/Dropdown";
import { LinkContainer } from "react-router-bootstrap";
import DropdownButton from "react-bootstrap/DropdownButton";
import { CgProfile } from "react-icons/cg";
import { IoSettingsOutline } from "react-icons/io5";
import { VscSignOut } from "react-icons/vsc";

const headerMenuStyle = {
    margin: "0.15em",
    marginLeft: "auto",
    padding: "0.2em",
};

const buttonIconStyle = {
    marginRight: "0.2em",
};

const buttonStyle = {
    display: "flex",
    alignItems: "center",
    height: "100%",
};

const Header = ({ signOut, user }) => {
    return (
        <div style={headerMenuStyle}>
            <DropdownButton title={user.attributes.name.split(" ").shift()} id="dropdown-menu-align-right">
                <LinkContainer to="/profile" style={buttonStyle}>
                    <Dropdown.Item>
                        <CgProfile style={buttonIconStyle} /> Profile
                    </Dropdown.Item>
                </LinkContainer>
                <LinkContainer to="/settings" style={buttonStyle}>
                    <Dropdown.Item>
                        <IoSettingsOutline style={buttonIconStyle} /> Settings
                    </Dropdown.Item>
                </LinkContainer>
                <Dropdown.Divider />
                <Dropdown.Item onClick={signOut} style={buttonStyle}>
                    <VscSignOut style={buttonIconStyle} /> Sign Out
                </Dropdown.Item>
            </DropdownButton>
        </div>
    );
};

export default Header;
