// eslint-disable-next-line no-unused-vars
import React, { Component } from "react";

const wipStyle = {
    textAlign: "center",
    margin: "1em 25% 0 25%",
};

class Wip extends Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    render() {
        return (
            <div style={wipStyle}>
                <h3>Nothing here yet! :)</h3>
            </div>
        );
    }
}

export default Wip;
