/* eslint-disable prefer-promise-reject-errors */
// eslint-disable-next-line no-unused-vars
import React, { useState, useEffect } from "react";
// eslint-disable-next-line no-unused-vars
import { API } from "aws-amplify";
// eslint-disable-next-line no-unused-vars
import BarLoader from "react-spinners/BarLoader";
// eslint-disable-next-line no-unused-vars
import RecipesTableDisplay from "./RecipesTableDisplay";

const loader = <BarLoader height={4} width={"100vw"} color={"#00BFFF"} />;

const recipeStyle = {
    // width: "100%",
    // display: "flex",
    // "justify-content": "center",
};

const Recipes = () => {
    const [state, setState] = useState({
        username: "",
        recipes: [{}],
        LastEvaluatedKey: undefined,
    });

    const apiPost = function (params) {
        return new Promise(function (resolve, reject) {
            const staticParams = {
                TableName: "FedorovskyRecipes-prod",
                Limit: 5,
            };

            API.post("FedorovskyApi", "/recipes", {
                body: {
                    type: "scan",
                    params: Object.assign(staticParams, params),
                },
            })
                .then((response) => {
                    // console.log("Response : ", response);
                    return resolve(response);
                })
                .catch((err) => {
                    console.log(err);
                    return reject("Encountered an error :(");
                });
        });
    };

    /*
    const loadMore = function () {
        if (!this.state.loadingMore && !this.state.loading) {
            // const setState = this.setState.bind(this);
            setState({ loadingMore: true });

            const params = {
                KeyConditions: {
                    item_type: {
                        ComparisonOperator: "EQ",
                        AttributeValueList: [
                            {
                                S: this.state.item_type,
                            },
                        ],
                    },
                },
                ExclusiveStartKey: this.state.LastEvaluatedKey,
            };
            if (this.state.item_name && this.state.item_name.length > 0) {
                params.KeyConditions.item_name = {
                    ComparisonOperator: "BEGINS_WITH",
                    AttributeValueList: [
                        {
                            S: this.state.item_name.toLowerCase(),
                        },
                    ],
                };
            }

            apiPost(params)
                .then((response) => {
                    setState({ items: this.state.items.concat(response.Items), LastEvaluatedKey: response.LastEvaluatedKey, loadingMore: false });
                })
                .catch((err) => {
                    setState({ items: err, loadingMore: false });
                });
        }
    };
    */

    useEffect(() => {
        setState({ loading: true });
        const params = {
            // KeyConditions: {
            //     item_type: {
            //         ComparisonOperator: "EQ",
            //         AttributeValueList: [
            //             {
            //                 S: this.state.item_type
            //             }
            //         ]
            //     }
            // }
        };
        apiPost(params)
            .then((response) => {
                setState({ recipes: response.Items, LastEvaluatedKey: response.LastEvaluatedKey, loading: false });
            })
            .catch((err) => {
                setState({ recipes: err, loading: false });
            });
    }, []);

    return (
        <div style={recipeStyle}>
            <h1>Recipes should be image + name, click to open full details</h1>
            {state.loading ? loader : RecipesTableDisplay(state.recipes)}
        </div>
    );
};

export default Recipes;
