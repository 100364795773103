// eslint-disable-next-line no-unused-vars
import React from "react";
// eslint-disable-next-line no-unused-vars
import { Row, Col } from "react-bootstrap";

const itemsGridSectionStyle = {
    width: "100%",
    display: "flex",
    flexDirection: "column",
};

const itemsGridStyle = {
    display: "grid",
    gridGap: "1em",
    gridTemplateColumns: "repeat(5, minmax(17em, 17em))",
    margin: "auto",
};

const itemStyle = {
    // border: "0.05em solid black",
    borderRadius: "1em",
    boxShadow: "0 0.1em 0.3em 0 rgba(0, 0, 0, 0.2), 0 0.1em 0.3em 0 rgba(0, 0, 0, 0.19)",
};

// const imageStyle = {};

const itemNameStyle = {
    textAlign: "center",
    fontWeight: "bold",
    fontSize: "1em",
    marginTop: "1em",
    marginBottom: "0.5em",
};

// const itemSubDetailsStyle = {
//     fontSize: "0.75em",
//     margin: 0,
// };

// const descriptionStyle = {};

const colStyle = { padding: 0 };

const rowStyle = { margin: 0 };

const effectAmountStyle = {
    marginLeft: "0.5em",
    marginBottom: "0",
};

// const effectImageStyle = {
//     marginLeft: "0.5em",
//     width: "auto",
//     height: "auto",
//     objectFit: "contain",
//     maxWidth: "100%",
//     maxHeight: "100%",
// };

const capitalize = function (string) {
    if (string && string.length > 0) {
        return string.replace(/(?:^|\s)\S/g, function (a) {
            return a.toUpperCase();
        });
    }
    return string;
};

const RecipesTableDisplay = (recipes) => {
    const renderTableDataRow = function (item) {
        if (!item.RecipeId || !item.RecipeAuthorId) return "";

        // pull image using API call since private
        // const url = `https://fedorovsky-pictures.s3.amazonaws.com/${recipe.thumbnail}`;
        // const alt = item.RecipeId;

        const ingredients = item.Ingredients.map((ingredient) => (
            <Row key={ingredient.name + item.RecipeId} style={rowStyle}>
                <p style={effectAmountStyle}>
                    {ingredient.quantity} {capitalize(ingredient.name)}
                </p>
            </Row>
        ));

        return (
            <div key={item.RecipeId} className="item" style={itemStyle}>
                <Row style={rowStyle}>
                    <Col style={colStyle}>{/* <img src="" alt={alt} width="100%" style={imageStyle} /> */}</Col>
                    <Col style={colStyle}>
                        <p style={itemNameStyle}>{capitalize(item.Name)}</p>
                    </Col>
                </Row>
                <Row style={rowStyle}>
                    <Col style={colStyle}>
                        {ingredients}
                        {/* <Row style={rowStyle}>
                            <img src={url} alt={alt} width="25" height="25" style={effectImageStyle} />
                            <p style={effectStyle}>1</p>
                        </Row>
                        <Row style={rowStyle}>
                            <img src={url} alt={alt} width="25" height="25" style={effectImageStyle} />
                            <p style={effectStyle}>1</p>
                        </Row> */}
                    </Col>
                </Row>
                {/* <Row style={rowStyle}>
                    <Col style={colStyle}>
                        <p style={descriptionStyle}>{item.description}</p>
                    </Col>
                </Row> */}
            </div>
        );
    };

    if (typeof recipes === "string") {
        return <p>{recipes}</p>;
    }
    if (recipes.length === 0) {
        return (
            <div className="items-grid">
                <h5>No items found</h5>
            </div>
        );
    }
    return (
        <section style={itemsGridSectionStyle}>
            <div className="items-grid" style={itemsGridStyle}>
                {recipes.map((tData) => {
                    return renderTableDataRow(tData);
                })}
            </div>
        </section>
    );
};

export default RecipesTableDisplay;
