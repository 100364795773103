/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "FedorovskyApi",
            "endpoint": "https://qj17gsh9m2.execute-api.ca-central-1.amazonaws.com/prod",
            "region": "ca-central-1"
        }
    ],
    "aws_cognito_identity_pool_id": "ca-central-1:3dbf2838-a3a1-4bd0-b01f-98403cdd3fa8",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_5O0dQGSfS",
    "aws_user_pools_web_client_id": "7q441g5rjfk55hh92okuuo3dlo",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "fedorovsky-prod-hostingbucket-prod",
    "aws_content_delivery_bucket_region": "ca-central-1",
    "aws_content_delivery_url": "https://d2pq578oap7gfv.cloudfront.net",
    "aws_dynamodb_all_tables_region": "ca-central-1",
    "aws_dynamodb_table_schemas": [
        {
            "tableName": "FedorovskyRecipes-prod",
            "region": "ca-central-1"
        }
    ]
};


export default awsmobile;
