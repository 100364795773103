import React from "react";
// import ProfileTestTableDisplay from "./ProfileTestTableDisplay";

const profileStyle = {
    textAlign: "center",
    margin: "1em 25% 0 25%",
};

const Profile = ({ user }) => {
    return (
        <div style={profileStyle}>
            <h3>Profile</h3>
            <p>Name: {user.attributes.name}</p>
            <p>Email: {user.attributes.email}</p>
            {/* <ProfileTestTableDisplay key="ProfileTestTableDisplay" mergeState={this.props.mergeState.bind(this.props.stateStore)} getState={this.props.getState.bind(this.props.stateStore)} tableData={this.state.media} reload={this.reload} editing={this.state.editing} /> */}
        </div>
    );
};

export default Profile;
